import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import {IUserModel, IUserWrapper} from "../models/UserModel";
import graphQl from "../services/GraphQL";
import {ICategoryModel} from "../models/CategoryModel";


export interface IUserState {
    user: IUserModel | null,
    currentUser: IUserModel | null,
    userRole: Array<string>| null,
    users: IUserWrapper | null,
    usersFilter: Array<IUserModel>|null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    page: number,
    pageSize: number,
    totalCount: number,
    totalUsersCount: IUserModel| null
}


//Request GET actions
export interface RequestGetUsers extends Action<string> {
    type: "REQUEST_GET_USERS";
}

export interface RequestGetUser extends Action<string> {
    type: "REQUEST_GET_USER";
}
export interface RequestGetCurrentUser extends Action<string> {
    type: "REQUEST_GET_CURRENT_USER";
}
export interface RequestGetTotalUserCount extends Action<string> {
    type: "REQUEST_GET_TOTAL_USERS_COUNT";
}
export interface RequestGetUsersFilter extends Action<string> {
    type: "REQUEST_GET_USERS_FILTER";
}

export interface RequestAddOrUpdateUser extends Action<string> {
    type: "REQUEST_ADD_UPDATE_USER";
}
export interface RequestUpdateUserRole extends Action<string>{
    type: "REQUEST_UPDATE_USER_ROLE";
}
export interface RequestGetUserRole extends Action<string>{
    type: "REQUEST_GET_USER_ROLE";
}
//Respond ADD actions
export interface RespondGetUsers extends Action<string> {
    type: "RESPOND_GET_USERS";
    payload: IUserWrapper | null;
    error: Error | null
}
export interface RespondGetUser extends Action<string> {
    type: "RESPOND_GET_USER";
    payload: IUserModel | null;
    error: Error | null
}
export interface RespondGetCurrentUser extends Action<string> {
    type: "RESPOND_GET_CURRENT_USER";
    payload: IUserModel | null;
    error: Error | null
}
export interface RespondGetTotalUsersCount extends Action<string> {
    type: "RESPOND_GET_TOTAL_USERS_COUNT";
    payload: IUserModel | null;
    error: Error | null
}
export interface RespondGetUsersFilter extends Action<string> {
    type: "RESPOND_GET_USERS_FILTER";
    payload: Array<IUserModel> | null;
    error: Error | null
}
export interface RespondAddOrUpdateUser extends Action<string> {
    type: "RESPOND_ADD_UPDATE_USER";
    payload: IUserModel | null;
    error: Error | null
}
export interface RespondUpdateUserRole extends Action<string>{
    type: "RESPOND_UPDATE_USER_ROLE";
    payload: IUserModel | null;
    error: Error | null;
}
export interface RespondGetUserRole extends Action<string>{
    type: "RESPOND_GET_USER_ROLE";
    payload: Array<string> | null;
    error: Error | null;
}
export interface ResetUser extends Action<string> {
    type: "RESET_USER";
}


type KnownAction = RequestGetUsers |
    RequestGetUser |
    RequestAddOrUpdateUser |
    RespondGetUsers |
    RespondGetUser |
    RequestGetCurrentUser |
    RespondGetCurrentUser |
    RespondAddOrUpdateUser |
    RequestGetUsersFilter |
    RespondGetUsersFilter |
    RequestUpdateUserRole |
    RespondUpdateUserRole |
    RequestGetUserRole |
    RespondGetUserRole |
    RequestGetTotalUserCount |
    RespondGetTotalUsersCount |
    ResetUser


export const actionCreators = {
    requestAddOrUpdateUser: (user: IUserModel, password: string, file:any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_USER" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ addOrUpdateUser: IUserModel }>(
                "addOrUpdateUser(user:$user,password:$password,file:$file){ userName, firstName, lastName, sex, email, phoneNumber, photo, dateOfBirth }",
                ["$user: UserInputType!, $password:String","$file:Upload"],
                {user, password, file }, authData);
            dispatch({ type: "RESPOND_ADD_UPDATE_USER", payload: data.addOrUpdateUser, error: null });
            dispatch({ type: "RESET_USER" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_USER", payload: null, error: e });
        }
    },
    requestGetUsers: (page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_USERS" });
            const authData = appState.authorization!.authData;
            const params = { page: page, pageSize: pageSize };
            const data = await graphQl.executeQuery<{ users: IUserWrapper }>(
                "users(page: $page, pageSize: $pageSize){items {id, userName, photo, fullName, firstName, lastName, email, address, phoneNumber, dateOfBirth, sex}page,pageSize,totalCount}",
                ["$page: Int!, $pageSize: Int!"],
                {page: page, pageSize: pageSize},authData );
            dispatch({ type: "RESPOND_GET_USERS", payload: data.users, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_USERS", payload: null, error: e });
        }

    },
    requestGetUser: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_USER" });
            const data = await graphQl.executeQuery<{ user: IUserModel }>(
                "user(id: $id){id fullName userName firstName lastName email phoneNumber dateOfBirth sex photo address}",
                ["$id:Int!"],
                { id });
            dispatch({ type: "RESPOND_GET_USER", payload: data.user, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_USER", payload: null, error: e });
        }
    },
    requestUpdateUserRole: (userId: number, role: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_UPDATE_USER_ROLE" });
            const authData = appState.authorization!.authData;
            const data = await graphQl.executeMutation<{ updateUserRole: IUserModel }>(
                "updateUserRole(userId:$userId,role:$role){id, userName}",
                ["$userId:Int!, $role:String"],
                { userId, role }, authData);
            dispatch({ type: "RESPOND_UPDATE_USER_ROLE", payload: data.updateUserRole, error: null });
            dispatch({ type: "RESET_USER" });
        } catch (e) {
            dispatch({ type: "RESPOND_UPDATE_USER_ROLE", payload: null, error: e });
        }
    },
    requestGetUserRole: (userId: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_USER_ROLE" });
            const data = await graphQl.executeQuery<{ userRole: Array<string> }>(
                "userRole(userId:$userId)",
                ["$userId:Int!"],
                { userId });
            dispatch({ type: "RESPOND_GET_USER_ROLE", payload: data.userRole, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_USER_ROLE", payload: null, error: e });
        }
    },
    requestGetTotalUsersCount:
        (): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                try {
                    const params = { };
                    dispatch({ type: "REQUEST_GET_TOTAL_USERS_COUNT" });
                    const data = await graphQl.executeQuery<{ totalUsersCount: IUserModel }>(
                        "totalUsersCount",
                        [],
                        {}
                    );
                    debugger
                    dispatch({
                        type: "RESPOND_GET_TOTAL_USERS_COUNT",
                        payload: data.totalUsersCount,
                        error: null,
                    });
                } catch (e) {
                    dispatch({ type: "RESPOND_GET_TOTAL_USERS_COUNT", payload: null, error: e });
                }
            },

    requestGetCurrentUser: (currentUserId:number|undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_CURRENT_USER" });
            const data = await graphQl.executeQuery<{ currentUser: IUserModel }>(
                "currentUser(currentUserId:$currentUserId){userName fullName email phoneNumber sex photo}",
                ["currentUserId:Int"],
                {currentUserId});

            dispatch({ type: "RESPOND_GET_CURRENT_USER", payload: data.currentUser, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CURRENT_USER", payload: null, error: e });
        }

    },

    requestGetUsersFilter: (filterUserId:number|undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_USERS_FILTER" });
            const data = await graphQl.executeQuery<{ usersFilter: Array<IUserModel> }>(
                "usersFilter(filterUserId:$filterUserId){ id fullName userName }",
                ["filterUserId:Int"],
                {filterUserId});

            dispatch({ type: "RESPOND_GET_USERS_FILTER", payload: data.usersFilter, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_USERS_FILTER", payload: null, error: e });
        }

    },

    resetUserState: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: "RESET_USER" });
    }
}

const unloadedState: IUserState = {
    user: null,
    currentUser: null,
    userRole: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isLoaded: false,
    isPosted: false,
    users: null,
    usersFilter: null,
    page: 1,
    pageSize: 10,
    totalCount: 0,
    totalUsersCount: null
}


export const reducer: Reducer<IUserState> = (state: IUserState | undefined, incomingAction: Action): IUserState => {

    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_USER":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_USER":
            return {
                ...state,
                user: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_USERS":
        case "REQUEST_GET_USER":
        case "REQUEST_GET_CURRENT_USER":
        case "REQUEST_GET_TOTAL_USERS_COUNT":
            return {
                ...state,
                isLoading: true
            }

        case "RESPOND_GET_USERS":
            if (action.error) {
                return {
                    ...state
                };
            }
            let newState = {
                ...state,
                users: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            };
            if (action.payload) {
                newState = {
                    ...newState,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                    totalCount: action.payload.totalCount
                }

            }
            return newState;


        case "RESPOND_GET_USER": {
            return {
                ...state,
                user: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }

        case "RESPOND_GET_CURRENT_USER": {
            return {
                ...state,
                currentUser: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "REQUEST_GET_USERS_FILTER":{
            return {
                ...state,
                usersFilter: [],
                fetchError: null,
                isLoading: true,
                isLoaded:false
            }
        }
        case "RESPOND_GET_USERS_FILTER":{
            return {
                ...state,
                usersFilter: action.payload,
                fetchError:action.error,
                isLoading:false,
                isLoaded:true
            }
        }
        case "REQUEST_UPDATE_USER_ROLE":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_UPDATE_USER_ROLE":
            return {
                ...state,
                user: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }
        case "REQUEST_GET_USER_ROLE": {
            return {
                ...state,
                userRole: null
            }
        }
        case "RESPOND_GET_USER_ROLE": {
            return {
                ...state,
                userRole: action.payload,
            }
        }
        case "RESPOND_GET_TOTAL_USERS_COUNT":{
            return {
                ...state,
                totalUsersCount: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "RESET_USER":
        {
            return {
                ...state,
                isLoaded: false,
                isLoading: false,
                isPosted: false,
                isPosting: false,
                page: 1,
                pageSize: 10,
                totalCount: 0,
            }
        }


        default:
            return state;
    }
}
