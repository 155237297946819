import * as Authorization from "./Authorization";
import * as Category from "./Category";
import * as Counter from "./Counter";
import * as News from "./News";
import * as NewsPublish from "./NewsPublish";
import * as Tag from "./Tag";
import * as User from "./User";
import * as Widget from "./Widget";
import * as WeatherForecasts from "./WeatherForecasts";

// The top-level state object
export interface ApplicationState {
  counter: Counter.CounterState | undefined;
  weatherForecasts: WeatherForecasts.WeatherForecastsState | undefined;
  news: News.INewsState | undefined;
  category: Category.ICategoryState | undefined;
  tag: Tag.ITagState | undefined;
  newsPublish: NewsPublish.INewsPublishState | undefined;
  user: User.IUserState | undefined;
  widget: Widget.IWidgetState | undefined;
  authorization: Authorization.IAuthorizationState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  counter: Counter.reducer,
  weatherForecasts: WeatherForecasts.reducer,
  news: News.reducer,
  category: Category.reducer,
  tag: Tag.reducer,
  newsPublish: NewsPublish.reducer,
  user: User.reducer,
  widget: Widget.reducer,
  authorization: Authorization.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
