import { IAuthData } from "../models/AuthData";
import { GraphQL as API } from "../common/ApiUrls";
import { GraphQLData } from "../models/CommonModels";
import http, { PostBodyType } from "./HttpService";
import axios from "axios";
interface IGraphQL {
    executeQuery<T>(query: string, params?: string[], variables?: any, authData?: IAuthData | null): Promise<T>;
    executeMutation<T>(query: string, params?: string[], inputs?: any, authData?: IAuthData | null,files?:FileList|null): Promise<T>;
}

class GraphQL implements IGraphQL {

    async executeQuery<T>(query: string, params?: string[], variables?: any, authData?: IAuthData | undefined): Promise<T> {
        const param = (params || [] as string[]).map((a: string) => a.startsWith("$") ? a : `$${a}`).join(", ");
        const gql = `query RootQuery ${(param.length === 0 ? "" : `(${param})`)} { ${query} }`;
        return await this.execute(gql, variables, authData);
    }

    async executeMutation<T>(query: string, params?: string[], inputs?: any, authData?: IAuthData | undefined, files?:FileList|null): Promise<T> {
        const param = (params || [] as string[]).map((a: string) => a.startsWith("$") ? a : `$${a}`).join(", ");
        const gql = `mutation ${(param.length === 0 ? "" : `(${param})`)} { ${query} }`;

        return await this.execute(gql, inputs, authData,files);
    }


    hasFile(obj: any): boolean {
        if (obj == null) return false;
        var keys = Object.keys(obj);
        for (var i = 0; i < keys.length; i++) {
            var props = obj[keys[i]];

            if (props instanceof File || props instanceof FileList) {
                return true;
            }
            //if (typeof props === "object") {
            //    var isFile = this.hasFile(props);
            //    if (isFile) return true;
            //}
        }

        return false;
    }

    async execute<T>(query: string, variables?: any, authData?: IAuthData | undefined,files?:FileList|null): Promise<T> {

        var postData, bodyType = PostBodyType.JSON;

        var isFile = this.hasFile(variables);

        if (isFile) {
            const formData = new FormData();
            

            var fileMapKeys = {} as any;

            var varKeys = Object.keys(variables);
            var appendableFiles = [];

            for (var i = 0, j=0; i < varKeys.length; i++) {
                var propKey = varKeys[i];
                var propValue = variables[propKey];
                if (propValue instanceof File || propValue instanceof FileList) {
                    if (propValue instanceof File) {
                        appendableFiles.push({ key: j.toString(), value: propValue});
                        fileMapKeys[`${j++}`] = [`variables.${propKey}`];
                        variables[propKey] = null;
                        
                    } else {
                        var arr: any[] = [];
                        
                        for (var k = 0; k < propValue.length; k++) {
                            appendableFiles.push({ key: j.toString(), value: propValue[k]});
                            fileMapKeys[`${j++}`] = [`variables.${propKey}.${k}`];
                            arr.push(null);
                        }
                        variables[propKey] = arr;
                    }
                }
            }

            var operations = { query, variables };
            formData.append("operations", JSON.stringify(operations)); 
            formData.append("map", JSON.stringify(fileMapKeys));

            for (var i = 0; i < appendableFiles.length; i++) {
                var item = appendableFiles[i];
                formData.append(item.key, item.value);
            }
            bodyType = PostBodyType.FormData;
            postData = formData;

        } else {
            postData = { query, variables };
        
        }
        const gqlData = await http.postAsync<GraphQLData<T>>(API, postData, bodyType, authData);
        if (gqlData.errors) {
            console.log(gqlData.errors);
        }

        return new Promise((resolve, reject) => {
            if (gqlData.data) {
                resolve(gqlData.data);
            } else {
                reject(gqlData.errors);
            }
        });
       
    }

}

export default new GraphQL() as IGraphQL;