import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";
import {ITagModel, ITagsWrapper} from "../models/TagModel";


export interface ITagState {
    tag: ITagModel | null,
    tags: ITagsWrapper | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    page: number,
    pageSize: number,
    totalCount: number,
    totalTagsCount: ITagModel | null
}
//Request Get Actions
export interface RequestGetTags extends Action<string> {
    type: "REQUEST_GET_TAGS";
}
export interface RequestGetTagsCount extends Action<string> {
    type: "REQUEST_GET_TAGS_COUNT";
}
export interface RequestGetTagsById extends Action<string> {
    type: "REQUEST_GET_TAGS_BY_ID";
}

//Request Add Actions
export interface RequestAddOrUpdateTags extends Action<string> {
    type: "REQUEST_ADD_UPDATE_TAGS";
}

//Response Get Actions

export interface RespondGetTags extends Action<string> {
    type: "RESPOND_GET_TAGS";
    payload: ITagsWrapper | null;
    error: Error | null
}
export interface RespondGetTagsCount extends Action<string> {
    type: "RESPOND_GET_TAGS_COUNT";
    payload: ITagModel | null;
    error: Error | null
}
export interface RespondGetNewsById extends Action<string> {
    type: "RESPOND_GET_TAGS_BY_ID";
    payload: ITagModel | null;
    error: Error | null
}

//Respond Add Actions
export interface RespondAddOrUpdateTags extends Action<string> {
    type: "RESPOND_ADD_UPDATE_TAGS";
    payload: ITagModel | null;
    error: Error | null
}
export interface ResetState extends Action<string> {
    type: "RESET_STATE";
}


type KnownAction = RequestGetTags |
    RequestGetTagsById |
    RequestAddOrUpdateTags |
    RespondGetTags |
    RespondAddOrUpdateTags |
    RequestGetTagsCount |
    RespondGetTagsCount |
    ResetState

export const actionCreators = {
    requestAddOrUpdateTags: (tagsInfo: ITagModel, fileList: FileList): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_TAGS" });
            //const authData = appState.authorization!.authData;

            const data = await graphQl.executeMutation<{ addOrUpdateTags: ITagModel }>("addOrUpdateTags(tagsInfo:$tagsInfo){ id, value }", ["$tagsInfo:TagsInputType!"], { tagsInfo }, null, fileList);

            dispatch({ type: "RESPOND_ADD_UPDATE_TAGS", payload: data.addOrUpdateTags, error: null });
            //dispatch({ type: "RESET_STATE" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_TAGS", payload: null, error: e });
        }
    },

    requestGetTags: (page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_TAGS" });
            //const authData = appState.authorization!.authData;
            const params = { page: page, pageSize: pageSize };
            const data = await graphQl.executeQuery<{ tags: ITagsWrapper }>("tags(page: $page, pageSize: $pageSize){items{ id, value }page,pageSize,totalCount}", ["$page:Int", "$pageSize:Int"], {page: page, pageSize: pageSize});
            ////debugger;
            dispatch({ type: "RESPOND_GET_TAGS", payload: data.tags, error: null });
            //dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_TAGS", payload: null, error: e });
        }

    },
    requestGetTotalTagsCount:
        (): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                try {
                    const params = { };
                    dispatch({ type: "REQUEST_GET_TAGS_COUNT" });
                    const data = await graphQl.executeQuery<{ totalTagsCount: ITagModel }>(
                        "totalTagsCount",
                        [],
                        {}
                    );
                    debugger
                    dispatch({
                        type: "RESPOND_GET_TAGS_COUNT",
                        payload: data.totalTagsCount,
                        error: null,
                    });
                } catch (e) {
                    dispatch({ type: "RESPOND_GET_TAGS_COUNT", payload: null, error: e });
                }
            },
    resetTagsState: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: "RESET_STATE" });
    }

}

const unloadedState: ITagState = {
    tag: null,
    tags: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isPosted: false,
    isLoaded: false,
    page: 1,
    pageSize: 20,
    totalCount: 0,
    totalTagsCount: null

}

export const reducer: Reducer<ITagState> = (state: ITagState | undefined, incomingAction: Action): ITagState => {
    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_TAGS":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_TAGS":

            var payload = action;
            //debugger;
            return {
                ...state,
                tag: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_TAGS":
        case "REQUEST_GET_TAGS_BY_ID":
        case "REQUEST_GET_TAGS_COUNT":
            return {
                ...state,
                isLoading: true
            }
        case "RESPOND_GET_TAGS": {
            var tagState = {
                ...state,
                tags: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true,
            };
            if(action.payload){
                tagState ={
                    ...tagState,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                    totalCount: action.payload.totalCount
                }
            }
            return tagState;
        }
        case "RESPOND_GET_TAGS_COUNT":{
                return {
                    ...state,
                    totalTagsCount: action.payload,
                    fetchError: action.error,
                    isLoading: false,
                    isLoaded: true,
                };
        }
        case "RESET_STATE": {
            //debugger;
            return {
                ...state,
                fetchError: null,
                isPosting: false,
                isLoading: false,
                isPosted: false,
                tag: null,
                tags: null,
                isLoaded: false,
                page: 1,
                pageSize: 20,
                totalCount: 0,
            }
        }

        default:
            return state;
    }


}