import { lazy } from "react";
import { IRoute, RouteBuilder } from "../models/RouteModels";
const SidebarComponent = lazy(() => import("../components/sidebar/Sidebar"));
const NewsComponent = lazy(() => import("../components/pages/news/News"));
const AddNewsComponent = lazy(() => import("../components/pages/news/AddNews"));
const EditNewsComponent = lazy(
  () => import("../components/pages/news/EditNews")
);
const CategoryComponent = lazy(
  () => import("../components/pages/category/Category")
);
const AddCategoryComponent = lazy(
  () => import("../components/pages/category/AddCategory")
);
const EditCategoryComponent = lazy(
  () => import("../components/pages/category/EditCategory")
);
const DashboardComponent = lazy(() => import("../components/pages/Dashboard"));
const TagsComponent = lazy(() => import("../components/pages/Tags"));
const PublishComponent = lazy(
  () => import("../components/pages/newsPublish/NewsPublishes")
);
const EditNewsPublishesComponent = lazy(
  () => import("../components/pages/newsPublish/EditNewsPublishes")
);
const NewsPreview = lazy(
  () => import("../components/pages/newsPublish/NewsPreview")
);
const WidgetComponent = lazy(
  () => import("../components/pages/widget/Widget")
);
const AddWidgetComponent = lazy(
  () => import("../components/pages/widget/AddWidget")
);
const EditWidgetComponent = lazy(
  () => import("../components/pages/widget/EditWidget")
);
// const Reporter = lazy(() => import("../components/pages/reporter/Reporter"));
// const AddReporter = lazy(() => import("../components/pages/reporter/AddReporter"));
// const EditReporter = lazy(() => import("../components/pages/reporter/EditReporter"));
const User = lazy(() => import("../components/pages/users/ViewUser"));
const AddUser = lazy(() => import("../components/pages/users/AddUser"));
const EditUser = lazy(() => import("../components/pages/users/EditUser"));
const LogOutComponent = lazy(() => import("../components/pages/LogOut"));



const newsRoute: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/news-list")
    .setComponent(NewsComponent)
    .setTitle("News List")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),
  RouteBuilder.getBuilder(2)
    .setIsExact(true)
    .setPath("/news/add-new")
    .setComponent(AddNewsComponent)
    .setTitle("Add News")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),
  RouteBuilder.getBuilder(3)
    .setPath("/news/edit/:newsId")
    .setComponent(EditNewsComponent)
    .setTitle("Edit News")
    .setIsLink(false)
    .setIsPrivate(true)
    .build(),
    RouteBuilder.getBuilder(2)
    .setPath("/publish/preview/:publishId")
    .setComponent(NewsPreview)
    .setTitle("Preview News")
    .setIsLink(false)
    .setIsPrivate(true)
    .build(),


];

const categoryRoute: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/categories")
    .setComponent(CategoryComponent)
    .setTitle("Category List")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),
  RouteBuilder.getBuilder(2)
    .setIsExact(true)
    .setPath("/category/add")
    .setComponent(AddCategoryComponent)
    .setTitle("Add Category")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),
  RouteBuilder.getBuilder(3)
    .setPath("/category/edit/:categoryId")
    .setComponent(EditCategoryComponent)
    .setTitle("Edit Category")
    .setIsLink(false)
    .setIsPrivate(true)
    .build(),
];

const TagsRoute: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/tags")
    .setComponent(TagsComponent)
    .setTitle("Tag List")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),
];

const PublishRoute: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/publish-list")
    .setComponent(PublishComponent)
    .setTitle("Publish List")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(2)
    .setPath("/publish/edit/:publishId")
    .setComponent(EditNewsPublishesComponent)
    .setTitle("Edit News")
    .setIsLink(false)
    .setIsPrivate(true)
    .build(),
];

const WidgetRoute: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/widget-list")
    .setComponent(WidgetComponent)
    .setTitle("Widget List")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

    RouteBuilder.getBuilder(2)
    .setPath("/widget/add")
    .setComponent(AddWidgetComponent)
    .setTitle("Add Widget")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(2)
    .setPath("/widget/edit/:widgetId")
     .setComponent(EditWidgetComponent)
    .setTitle("Edit Widget")
    .setIsLink(false)
    .setIsPrivate(true)
    .build(),
];
const userRoute: IRoute[] = [
  RouteBuilder.getBuilder(1)
      .setPath("/user/user-list")
      .setComponent(User)
      .setTitle("User List")
      .setIsLink(true)
      .setIsPrivate(true)
      .build(),
  RouteBuilder.getBuilder(2)
      .setIsExact(true)
      .setPath("/user/add-user")
      .setComponent(AddUser)
      .setTitle("Add User")
      .setIsLink(true)
      .setIsPrivate(true)
      .build(),
  RouteBuilder.getBuilder(3)
      .setPath("/user/edit/:userId")
      .setComponent(EditUser)
      .setTitle("Edit User")
      .setIsLink(false)
      .setIsPrivate(true)
      .build(),
];
const mainRoutes: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/dashboard")
    .setComponent(DashboardComponent)
    .setTitle("Dashboard")
    .setIcon("pe-7s-graph")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(2)
    .setChildren(categoryRoute)
    .setTitle("Category")
    .setIcon("pe-7s-box1")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(3)
    .setChildren(newsRoute)
    .setTitle("News")
    .setIcon("pe-7s-box1")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(4)
    .setChildren(PublishRoute)
    .setTitle("Publish")
    .setIcon("pe-7s-box1")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

    RouteBuilder.getBuilder(5)
    .setChildren(WidgetRoute)
    .setTitle("Widget")
    .setIcon("pe-7s-box1")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(6)
    .setChildren(TagsRoute)
    .setTitle("Tag")
    .setIcon("pe-7s-box1")
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),
    
  RouteBuilder.getBuilder(7)
      .setChildren(userRoute)
      .setTitle("User")
      .setIcon("pe-7s-box1")
      .setIsLink(true)
      .setIsPrivate(true)
      .build(),

  RouteBuilder.getBuilder(8)
    .setIcon("pe-7s-power")
    .setTitle("Logout")
    .setPath("/logout")
    .setComponent(LogOutComponent)
    .setIsLink(true)
    .setIsPrivate(true)
    .build(),

  RouteBuilder.getBuilder(0)
    .setPath("/")
    .setComponent(DashboardComponent)
    .setIsLink(false)
    .setIsPrivate(true)
    .build(),
];

export default mainRoutes;
