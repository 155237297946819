import { Action, Reducer } from "redux";
import { Auth } from "../common/settings/security";
import { IAuthData } from "../models/AuthData";
import graphQl from "../services/GraphQL";
import { AppThunkAction } from "./";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IAuthorizationState {
  isLoading: boolean;
  authData: IAuthData | null;
  error?: Error;
  isAuth: boolean;
}

export class AuthorizationState implements IAuthorizationState {
  isLoading: boolean;
  authData: IAuthData | null;
  error?: Error;
  isAuth: boolean;
  hasAuth(): boolean {
    return !!this.authData;

  }

  constructor(isLoading: boolean, authData: IAuthData | null, error?: Error) {
    this.isLoading = isLoading;
    this.authData = authData;
    this.error = error;
    this.isAuth = this.hasAuth();
  }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestLoginAction extends Action<string> {
  type: "REQUEST_LOGIN";
}

interface SuccessLoginAction extends Action<string> {
  type: "SUCCESS_LOGIN";
  authData: IAuthData;
}

interface RequestLogOut extends Action<string> {
  type: "REQUEST_LOGOUT";
}

interface RespondLogOut extends Action<string> {
  type: "RESPOND_LOGOUT";
  authData: IAuthData | null;
}

interface ErrorLoginAction extends Action<string> {
  type: "ERROR_LOGIN";
  error: Error;
}

export interface CheckLoginAction extends Action<string> {
  type: "CHECK_LOGIN";
  error: Error;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
  | RequestLoginAction
  | SuccessLoginAction
  | ErrorLoginAction
  | CheckLoginAction
  | RespondLogOut
  | RequestLogOut;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  requestLogin:
    (userName: string, password: string): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      // Only load data if it's something we don't already have (and are not already loading)
      const appState = getState();

      try {
        dispatch({ type: "REQUEST_LOGIN" });
        debugger;
        const data = await graphQl.executeQuery<{ authorize: IAuthData }>(
          "authorize(userName:$userName,password:$password){expiresIn,token,refreshToken,issuer,roles,self{id,fullName,email}}",
          ["$userName:String!", "$password:String!"],
          { userName, password }
        );

        dispatch({ type: "SUCCESS_LOGIN", authData: data.authorize });
      } catch (e) {
        dispatch({ type: "ERROR_LOGIN", error: e });
      }
    },
  requestLogOut:
    (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
      const appState = getState();
      try {
        dispatch({ type: "REQUEST_LOGOUT" });
        // const authData = appState.authorization!.authData;

        // const data = await graphQl.executeQuery<{ logOut: IAuthData }>(
        //   "logOut{refreshToken}",
        //   [],
        //   {},
        //   authData
        // );

        dispatch({ type: "RESPOND_LOGOUT", authData: null });
      } catch (e) {
        dispatch({ type: "ERROR_LOGIN", error: e });
      }
    },

  checkLogin: () => ({ type: "CHECK_LOGIN" } as CheckLoginAction),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: IAuthorizationState = new AuthorizationState(
  false,
  Auth.get()
);

export const reducer: Reducer<IAuthorizationState> = (
  state: IAuthorizationState | undefined,
  incomingAction: Action
): IAuthorizationState => {
  if (state === undefined) {
    return { ...unloadedState };
  }

  const action = incomingAction as KnownAction;

  switch (action.type) {
    case "REQUEST_LOGIN":
      return { ...new AuthorizationState(true, null) };

    case "SUCCESS_LOGIN": {
      // Only accept the incoming data if it matches the most recent request. This ensures we correctly
      // handle out-of-order responses.
      const newState = new AuthorizationState(false, action.authData);
      Auth.set(newState.authData);
      //db.setItem(AUTH_DATA, newState.authData);
      return { ...newState };
    }

    case "REQUEST_LOGOUT":
      return { ...state };

    case "RESPOND_LOGOUT":
      const newState = new AuthorizationState(false, action.authData);
      Auth.remove();
      return { ...newState };

    case "ERROR_LOGIN":
      return { ...new AuthorizationState(false, null, action.error) };

    case "CHECK_LOGIN": {
      if (state.authData == null) {
        return state;
      }
      return { ...new AuthorizationState(false, state.authData) };
    }

    default:
      return state;
  }
};
