// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = "/";
export const LOGIN = "/login";

export const HOME = "/home";
export const NEWS = "/news";
export const ADD_NEWS = "/news/add";
export const EDIT_NEWS = "/edit-news/:id";
export const DETAILS_NEWS = "/news/details/:id";
export const CATEGORIES = "/categories";
export const ADD_CATEGORY = "/category/add";
export const EDIT_CATEGORY = "/category/edit/:id";
export const DETAILS_CATEGORY = "/category/details/:id";
export const TAGS = "/tags";
export const PUBLISH = "/publish";
export const EDIT_NEWS_PUBLISH = "/edit-publish/:id";
export const WIDGETS = "/widget-list";
export const ADD_WIDGET = "/widget/add";
export const EDIT_WIDGET = "/widget/edit/:id";
// **************** ROUTE CONSTANT END **************************

export const CURRENCY = "৳";
export const AUTH_DATA = "7c542d0b-e857-4c52-a54f-59585b43a52e";
