// import * as React from "react";
import { RouteLooper } from "./components/common/RouteLooper";
import routes from "./routes/routes.Main";
import { Bounce, ToastContainer } from "react-toastify";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Auth } from "./common/settings/security";
import "./custom.css";
import { createUploadLink } from "apollo-upload-client";
import Routes from "./routes";
// export default class App extends React.PureComponent {
//   render() {
//     return (
//       <React.Fragment>
//         <RouteLooper routes={routes} />
//         <ToastContainer
//             position="top-right"
//             autoClose={5000}
//             hideProgressBar={false}
//             newestOnTop={false}
//             transition={Bounce}
//             closeOnClick
//             rtl={false}
//             pauseOnFocusLoss
//             draggable
//             pauseOnHover
//         />
//       </React.Fragment>
//     );
//   }
// }
const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL + "graphql",
});

declare global {
  interface Array<T> {
    groupBy(elem: T): any;
  }
}

Array.prototype.groupBy = function (key) {
  return this.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const App = () => {
  const authLink = setContext((_: any, { headers }: any) => {
    const authData = Auth.get();
    return {
      headers: {
        ...headers,
        authorization:
          authData && authData.token ? `Bearer ${authData.token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  return (
    <div className="App">
      <ApolloProvider client={client as any}>
        <Router>
          <RouteLooper routes={routes} />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            transition={Bounce}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Router>
      </ApolloProvider>
    </div>
  );
};
export default App;
