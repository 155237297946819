import React,{useState} from "react";
import { connect } from "react-redux";
import {Redirect, useHistory} from "react-router-dom";
import * as Authorization from "../../store/Authorization";
import { ApplicationState } from "../../store/index";
import logo from "../img/drishty-71.png";
import "../../custom.css"
import {toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
interface ILoginState {
  userName: string;
  password: string;
}

type LoginProps = ILoginState &
  Authorization.IAuthorizationState &
  typeof Authorization.actionCreators;

const Login = (props:any) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  function handleLogin(event: React.FormEvent<HTMLFormElement>): void {
    event.preventDefault();
    props.requestLogin(userName, password);
  }

      const { isAuth, authData } = props;
  if (isAuth && authData !== null && authData.roles) {
    toast.success('Login Successfully');
    return <Redirect to={{
        pathname:"/"
      }}/>;
    }

    return (
      <div className="login">
        <div className="container-fluid">
          <div
            className="col-xl-5 col-lg-4 col-md-6 col-sm-12 login"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "250px",
            }}
          >
            <div className="card mb-3 mt-1 shadow p-3 bg-white rounded fadeInDown">
              <div className="align-self-center">
                <div>
                  <img className="logo" src={logo} alt="logo" />
                </div>
              </div>
              <div className="card-header text-center bg-success ml-3 mr-3">
                <h3 className="card-title text-white">Login</h3>
              </div>
              <div className="card-body">
                <form onSubmit={handleLogin}>
                  <div className="col-md-12 form-floating mb-3">
                    <input
                      type="text"
                      name="userName"
                      className="form-control border-0 border-bottom-red"
                      id="floatingInput"
                      placeholder="name@example.com"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <label className='ml-2' htmlFor="floatingInput">Username</label>
                  </div>
                  <div className="col-md-12 form-floating mb-3">
                    <input
                      type="password"
                      name="password"
                      value={password}
                      className="form-control border-0 border-bottom-red"
                      id="floatingInput"
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="name@example.com"
                    />
                    <label className='ml-2' htmlFor="floatingInput">Password</label>
                  </div>

                  <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                    <button
                      type="submit"
                      className="btn px-5 py-2 loginBtn"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Submit"
                    >
                      Login
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default connect(
  (state: ApplicationState) => {
    return { ...state.authorization };
  },
  { ...Authorization.actionCreators }
)(Login as any);
