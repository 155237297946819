import { History, LocationState } from "history";
import cloneDeep from "lodash/cloneDeep";
import * as React from "react";
import { connect } from "react-redux";
import { IRoute } from "../../models/RouteModels";
import mainRoutes from "../../routes/routes.Authorized";
import { ApplicationState } from "../../store";
import * as Login from "../../store/Authorization";
import { RouteLooper } from "../common/RouteLooper";
//import AdminNavbar from "../Navbars/AdminNavbar";
//import Footer from "../Footer/Footer";
import Sidebar from "../sidebar/Sidebar";

export interface IAuthorizedLayoutProps {
  location: Location;
  history: History<LocationState>;
}

export interface AuthorizedLayoutState {
  image: any;
  color: string;
  hasImage: boolean;
  routes: IRoute[];
}

type AuthorizedLayoutProps = IAuthorizedLayoutProps & Login.AuthorizationState;

class AuthorizedLayout extends React.PureComponent<
  AuthorizedLayoutProps,
  AuthorizedLayoutState
> {
  filterRoutes = (routes: any) => {
    return routes.filter((r: any) => {
      const canAdd = true;
      // !r.roles ||
      // r.roles.some((b: any) => this.props.authData?.roles.includes(b));
      if (canAdd && r.children) {
        r.children = this.filterRoutes(r.children);
      }
      return canAdd;
    });
  };

  state = {
    image: "",
    color: "black",
    hasImage: true,
    routes: this.filterRoutes(cloneDeep(mainRoutes)),
  };

  private mainPanel = React.createRef<HTMLDivElement>();

  componentDidUpdate(e: AuthorizedLayoutProps) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement!.scrollTop = 0;
      this.mainPanel.current!.scrollTop = 0;
    }
  }

  render(): any {
    return (
      <div className="wrapper">
        {/*<NotificationSystem ref="notificationSystem" style={style} />*/}
        <Sidebar {...this.props} routes={this.state.routes} />

        <div id="main-panel" className="main-panel" ref={this.mainPanel}>
          <div style={{ minHeight: `${window.innerHeight - 62}px` }}>
            <React.Suspense fallback={<div>Loading...</div>}>
              <RouteLooper routes={this.state.routes} />
            </React.Suspense>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state: ApplicationState) => {
    return { ...state.authorization };
  },
  { ...Login.actionCreators }
)(AuthorizedLayout as any);
