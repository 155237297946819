import * as React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import * as AuthStore from "../../store/Authorization";
import { ApplicationState } from "../../store/index";

export type PrivateRouteProps = AuthStore.IAuthorizationState &
  typeof AuthStore.actionCreators &
  RouteProps;

class PrivateRoute extends React.PureComponent<PrivateRouteProps> {
  componentDidMount(): void {
    this.props.checkLogin();

    window.addEventListener("focus", this.focusHandler, false);
  }

  focusHandler = () => {
    this.props.checkLogin();
  };

  render(): any {
    return this.props.isAuth ? (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        component={this.props.component as React.ComponentType}
      />
    ) : (
      <Redirect from={this.props.path as string} to={"/login"} />
    );
  }

  componentWillUnmount(): void {
    window.removeEventListener("focus", this.focusHandler, false);
  }
}

export default connect(
  (state: ApplicationState) => state.authorization,
  AuthStore.actionCreators
)(PrivateRoute as any);
