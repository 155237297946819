import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";
import { INewsPublishModel } from "../models/NewsPublishModel";
import http, { PostBodyType } from "../services/HttpService";

export interface INewsPublishState {
    newsPublish: INewsPublishModel | null,
    fetchError: Error | null,
    isPostingPublish: boolean,
    isLoadingPublishList: boolean,
    isLoadedPublishList: boolean,
    isPostedPublish: boolean,   
    newsPublishes: Array<INewsPublishModel> | null,
   
   
}
//Request Get Actions
export interface RequestGetNewsPublishes extends Action<string> {
    type: "REQUEST_GET_NEWS_PUBLISHES";
}
export interface RequestGetNewsPublishesById extends Action<string> {
    type: "REQUEST_GET_NEWS_PUBLISHES_BY_ID";
}



//Request Add Actions
export interface RequestAddOrUpdateNewsPublishes extends Action<string> {
    type: "REQUEST_ADD_UPDATE_NEWS_PUBLISH";
}

//Response Get Actions

export interface RespondGetNewsPublishes extends Action<string> {
    type: "RESPOND_GET__NEWS_PUBLISHES";
    payload: Array<INewsPublishModel>|null;
    error: Error | null
}
export interface RespondGetNewsPublishesById extends Action<string> {
    type: "RESPOND_GET_NEWS_PUBLISH_BY_ID";
    payload: INewsPublishModel | null;
    error: Error | null
}

//Respond Add Actions
export interface RespondAddOrUpdateNewsPublishes extends Action<string> {
    type: "RESPOND_ADD_UPDATE_NEWS_PUBLISH";
    payload: INewsPublishModel | null;
    error: Error | null
}
export interface ResetState extends Action<string> {
    type: "RESET_STATE";       
}



type KnownAction = RequestGetNewsPublishes |
    RequestGetNewsPublishesById |    
    RespondGetNewsPublishesById |    
    RequestAddOrUpdateNewsPublishes |
    RespondGetNewsPublishes |
    RespondAddOrUpdateNewsPublishes |
    ResetState 
   


export const actionCreators = {
    requestAddOrUpdateNewsPublish: (newsPublishInfo: INewsPublishModel): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_NEWS_PUBLISH" });
            //const authData = appState.authorization!.authData;           

            const data = await graphQl.executeMutation<{ addOrUpdateNewsPublish: INewsPublishModel }>("addOrUpdateNewsPublish(newsPublishInfo:$newsPublishInfo){ id, startDate,endDate,news{id,title},status}", ["$newsPublishInfo:PublishInputType!"], { newsPublishInfo }, null);

            dispatch({ type: "RESPOND_ADD_UPDATE_NEWS_PUBLISH", payload: data.addOrUpdateNewsPublish, error: null });
            //dispatch({ type: "RESET_STATE" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_NEWS_PUBLISH", payload: null, error: e });
        }
    },

    requestGetNewsPublishes: (page:number,pageSize:number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_NEWS_PUBLISHES" });
            //const authData = appState.authorization!.authData;
            const params = {page:page,pageSize:pageSize}
            const data = await graphQl.executeQuery<{ publishNewsList: Array<INewsPublishModel> }>("publishNewsList{ id, startDate,endDate,news{id, title},status}", [], {});
            dispatch({ type: "RESPOND_GET__NEWS_PUBLISHES", payload: data.publishNewsList, error: null });
            //dispatch({ type: "RESET_STATE" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET__NEWS_PUBLISHES", payload: null, error: e });
        }

    },
    requestGetNewsPublishById: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_NEWS_PUBLISHES_BY_ID" });

            //const authData = appState.authorization!.authData;
            const params = { id: id }
            const data = await graphQl.executeQuery<{ singlePublishNews: INewsPublishModel }>("singlePublishNews(id:$id){ id, startDate,endDate,news{title},status}",["$id:Int!"], { id: id });
            dispatch({ type: "RESPOND_GET_NEWS_PUBLISH_BY_ID", payload: data.singlePublishNews, error: null });
            //dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_NEWS_PUBLISH_BY_ID", payload: null, error: e });
        }

    },   

    resetNewsPublishState: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: "RESET_STATE" });
    }
   
    
}


const unloadedState: INewsPublishState = {
    newsPublish: null,
    fetchError: null,
    isPostingPublish: false,
    isLoadingPublishList: false,
    isPostedPublish: false,    
    newsPublishes: [],
    isLoadedPublishList: false,
  
   
}

export const reducer: Reducer<INewsPublishState> = (state: INewsPublishState | undefined, incomingAction: Action): INewsPublishState => {
    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_NEWS_PUBLISH":
            return {
                ...state,
                isPostingPublish: true
            }
        case "RESPOND_ADD_UPDATE_NEWS_PUBLISH":

            debugger;
            return {
                ...state,
                newsPublish: action.payload,
                fetchError: action.error,
                isPostingPublish: false,
                isPostedPublish: action.payload !== null
            }

        case "REQUEST_GET_NEWS_PUBLISHES":return {
            ...state,
            newsPublishes:[],
            isLoadingPublishList: true
        }
        case "REQUEST_GET_NEWS_PUBLISHES_BY_ID":
            return {
                ...state,
                isLoadingPublishList: true
            }
        case "RESPOND_GET__NEWS_PUBLISHES": {
            return {
                ...state,
                newsPublishes:action.payload,
                fetchError: action.error,
                isLoadingPublishList: false,
                isLoadedPublishList: true
            }
        }

        case "RESPOND_GET_NEWS_PUBLISH_BY_ID": {
            return {
                ...state,
                newsPublish: action.payload,
                fetchError: action.error,
                isLoadingPublishList: false,
                isLoadedPublishList: true
            }
        }

        

       
        case "RESET_STATE": {
            return {
                ...state,
                fetchError: null,
                isPostingPublish: false,
                isLoadingPublishList: false,
                isPostedPublish: false,
                newsPublishes: null,
                isLoadedPublishList: false
            }
        }

        default:
            return state;
    }


}