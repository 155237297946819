import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";
import { ICategoryModel, ICategoryWrapper } from "../models/CategoryModel";
import http, {PostBodyType} from "../services/HttpService";


export interface ICategoryState {
    category: ICategoryModel | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    categories: ICategoryWrapper | null
    categoriesFilter:Array<ICategoryModel>|null,
    page: number,
    pageSize: number,
    totalCount: number,
    totalCategoriesCount: ICategoryModel | null

}
//Request Get Actions
export interface RequestGetCategories extends Action<string> {
    type: "REQUEST_GET_CATEGORIES";
}
export interface RequestGetCategory extends Action<string> {
    type: "REQUEST_GET_CATEGORY";
}
export interface RequestGetCategoriesCount extends Action<string> {
    type: "REQUEST_GET_CATEGORIES_COUNT";
}
export interface RequestGetCategoriesFilter extends Action<string> {
    type: "REQUEST_GET_CATEGORIES_FILTER";
}
//Request Add Actions
export interface RequestAddOrUpdateCategory extends Action<string> {
    type: "REQUEST_ADD_UPDATE_CATEGORY";
}

//Response Get Actions
export interface RespondGetCategories extends Action<string> {
    type: "RESPOND_GET_CATEGORIES";
    payload: ICategoryWrapper | null;
    error: Error | null
}
export interface RespondGetCategoriesFilter extends Action<string> {
    type: "RESPOND_GET_CATEGORIES_FILTER";
    payload: Array<ICategoryModel> | null;
    error: Error | null
}

export interface RespondGetCategory extends Action<string> {
    type: "RESPOND_GET_CATEGORY";
    payload: ICategoryModel | null;
    error: Error | null
}
export interface RespondGetCategoriesCount extends Action<string> {
    type: "RESPOND_GET_CATEGORIES_COUNT";
    payload: ICategoryModel | null;
    error: Error | null
}
//Respond Add Actions
export interface RespondAddOrUpdateCategory extends Action<string> {
    type: "RESPOND_ADD_UPDATE_CATEGORY";
    payload: ICategoryModel | null;
    error: Error | null
}
//Set State
export interface ResetState extends Action<string> {
    type:  "RESET_STATE";
}

type KnownAction = RequestGetCategories |
    RequestGetCategory |
    RespondGetCategory |
    RequestAddOrUpdateCategory |
    RespondGetCategories |
    RespondAddOrUpdateCategory |
    RequestGetCategoriesFilter |
    RespondGetCategoriesFilter |
    RequestGetCategoriesCount |
    RespondGetCategoriesCount |
    ResetState 


export const actionCreators = {
    requestAddOrUpdateCategory: (categoryInfo: ICategoryModel,file:any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_CATEGORY" });
            //const authData = appState.authorization!.authData;
            if (typeof file !== "undefined" && file != null) {
                let formData = new FormData();
                formData.append("file", file as any);

                let imageUploader = (await http.postAsync<object>(
                    "api/imageUploads",
                    formData,
                    PostBodyType.FormData
                )) as any;
                categoryInfo.iconImage = imageUploader.imageName;
            }
            const data = await graphQl.executeMutation<{ addOrUpdateCategory: ICategoryModel }>("addOrUpdateCategory(categoryInfo:$categoryInfo,file:$file){ id,title,note,parentId,viewType,categoryTags{tag{value,id}}}", ["$categoryInfo:CategoryInputType!","$file:Upload"], { categoryInfo,file});
            dispatch({ type: "RESPOND_ADD_UPDATE_CATEGORY", payload: data.addOrUpdateCategory, error: null });
            //dispatch({ type: "RESET_STATE" });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_CATEGORY", payload: null, error: e });
        }
    },
    requestGetCategories: (page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_CATEGORIES" });
            //const authData = appState.authorization!.authData;
            const params = { page: page, pageSize: pageSize };
            const data = await graphQl.executeQuery<{ categories: ICategoryWrapper }>(
                "categories(page: $page, pageSize: $pageSize){items{ id,iconImage,parentCategory {title},titleUrl, title,note,priority,viewType }page,pageSize,totalCount}",
                ["$page:Int", "$pageSize:Int"],
                {page: page, pageSize: pageSize});
            debugger
            dispatch({ type: "RESPOND_GET_CATEGORIES", payload: data.categories, error: null });
            //dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CATEGORIES", payload: null, error: e });
        }

    },

    requestGetCategoriesFilter: (excludeId:number|undefined): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_CATEGORIES_FILTER" });
            //const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ categoriesFilter: Array<ICategoryModel> }>("categoriesFilter(excludeCategoryId:$excludeCategoryId){ id,titleUrl, title,iconImage,parentCategory {title},note,priority,viewType }", ["$excludeCategoryId:Int"], {excludeCategoryId: excludeId });

            dispatch({ type: "RESPOND_GET_CATEGORIES_FILTER", payload: data.categoriesFilter, error: null });
            //dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CATEGORIES_FILTER", payload: null, error: e });
        }

    },

 requestGetCategoryById: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        //const appState = getState();
        try {
            dispatch({ type: "REQUEST_GET_CATEGORY" });
            //const authData = appState.authorization!.authData;
            const data = await graphQl.executeQuery<{ category: ICategoryModel }>("category:categoryById(id:$id){id,title,titleUrl,priority,note,parentId,viewType,iconImage,categoryTags{tag{value,id,active}}}", ["$id:Int!"], { id });
            dispatch({ type: "RESPOND_GET_CATEGORY", payload: data.category, error: null });
            //dispatch({ type: "RESET_PRODUCT" });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_CATEGORY", payload: null, error: e });
        }

    },
    requestGetCategoriesCount:
        (): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                try {
                    const params = { };
                    dispatch({ type: "REQUEST_GET_CATEGORIES_COUNT" });
                    const data = await graphQl.executeQuery<{ totalCategoriesCount: ICategoryModel }>(
                        "totalCategoriesCount",
                        [],
                        {}
                    );
                    debugger
                    dispatch({
                        type: "RESPOND_GET_CATEGORIES_COUNT",
                        payload: data.totalCategoriesCount,
                        error: null,
                    });
                } catch (e) {
                    dispatch({ type: "RESPOND_GET_CATEGORIES_COUNT", payload: null, error: e });
                }
            },
    resetCategoryState: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: "RESET_STATE" });
    }
}

const unloadedState: ICategoryState = {
    category: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isPosted: false,
    categories: null,
    categoriesFilter:null,
    isLoaded: false,
    page: 1,
    pageSize: 20,
    totalCount: 0,
    totalCategoriesCount: null

}

export const reducer: Reducer<ICategoryState> = (state: ICategoryState | undefined, incomingAction: Action): ICategoryState => {
    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_CATEGORY":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_CATEGORY":
            return {
                ...state,
                category: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_CATEGORIES":
        case "REQUEST_GET_CATEGORY":
        case "REQUEST_GET_CATEGORIES_COUNT":
            return {
                ...state,
                category: null,
                isLoading: true
            }
        case "RESPOND_GET_CATEGORIES": {
            if (action.error) {
                return {
                    ...state
                };
            }
            let newState = {
                ...state,
                categories: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            };
            if (action.payload) {
                newState = {
                    ...newState,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                    totalCount: action.payload.totalCount
                }

            }
            return newState;
        }

        case "RESPOND_GET_CATEGORY": {
            return {
                ...state,
                category: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "RESPOND_GET_CATEGORIES_COUNT": {
            return {
                ...state,
                totalCategoriesCount: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "REQUEST_GET_CATEGORIES_FILTER":{
            return {
                ...state,
                categoriesFilter: [],
                fetchError: null,
                isLoading: true,
                isLoaded:false
            }
        }

        case "RESPOND_GET_CATEGORIES_FILTER":{
            return {
                ...state,
                categoriesFilter: action.payload,
                fetchError:action.error,
                isLoading:false,
                isLoaded:true
            }
        }


        case "RESET_STATE": {
            return {
                ...state,
                fetchError: null,
                isPosting: false,
                isLoading: false,
                isPosted: false,
                isLoaded: false,
                page: 1,
                pageSize: 20,
                totalCount: 0,
            }
        }
        default:
            return state;
    }

}