import { Action, Reducer } from "redux";
import { AppThunkAction } from "./index";
import graphQl from "../services/GraphQL";
import http, {PostBodyType} from "../services/HttpService";
import {IWidgetModel, IWidgetWrapper} from "../models/WidgetModel";


export interface IWidgetState {
    widget: IWidgetModel | null,
    fetchError: Error | null,
    isPosting: boolean,
    isLoading: boolean,
    isLoaded: boolean,
    isPosted: boolean,
    widgets: IWidgetWrapper | null
    page: number,
    pageSize: number,
    totalCount: number,
    totalWidgetsCount: IWidgetModel | null

}
//Request Get Actions
export interface RequestGetWidgets extends Action<string> {
    type: "REQUEST_GET_WIDGETS";
}
export interface RequestGetWidget extends Action<string> {
    type: "REQUEST_GET_WIDGET";
}
export interface RequestGetWidgetsCount extends Action<string> {
    type: "REQUEST_GET_WIDGETS_COUNT";
}

export interface RequestGetActiveStatus extends Action<string> {
    type: "REQUEST_GET_WIDGET_STATUS";
}

export  interface RequestGetActiveWidgets extends Action<string>{
    type:"REQUEST_GET_ACTIVE_WIDGETS"

}
//Request Add Actions
export interface RequestAddOrUpdateWidget extends Action<string> {
    type: "REQUEST_ADD_UPDATE_WIDGET";
}

//Response Get Actions
export interface RespondGetWidgets extends Action<string> {
    type: "RESPOND_GET_WIDGETS";
    payload: IWidgetWrapper | null;
    error: Error | null
}

export interface RespondGetWidget extends Action<string> {
    type: "RESPOND_GET_WIDGET";
    payload: IWidgetModel | null;
    error: Error | null
}
export interface RespondGetWidgetsCount extends Action<string> {
    type: "RESPOND_GET_WIDGETS_COUNT";
    payload: IWidgetModel | null;
    error: Error | null
}

export interface RespondGetActiveStatus extends Action<string> {
    type: "RESPOND_GET_WIDGET_STATUS";
    payload: IWidgetModel | null;
    error: Error | null
}
export interface RespondGetActiveWidgets extends Action<string> {
    type: "RESPOND_GET_ACTIVE_WIDGETS";
    payload: IWidgetModel | null;
    error: Error | null
}
//Respond Add Actions
export interface RespondAddOrUpdateWidget extends Action<string> {
    type: "RESPOND_ADD_UPDATE_WIDGET";
    payload: IWidgetModel | null;
    error: Error | null
}
//Set State
export interface ResetState extends Action<string> {
    type:  "RESET_STATE";
}

type KnownAction = RequestGetWidgets |
    RequestGetWidget |
    RequestAddOrUpdateWidget |
    RespondGetWidgets |
    RespondGetWidget |
    RespondAddOrUpdateWidget |
    RequestGetWidgetsCount |
    RespondGetWidgetsCount |
    RequestGetActiveStatus |
    RespondGetActiveStatus |
    RequestGetActiveWidgets|
    RespondGetActiveWidgets |
    ResetState


export const actionCreators = {
    requestAddOrUpdateWidget: (widgetInfo: IWidgetModel,file:any): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const appState = getState();
        try {
            dispatch({ type: "REQUEST_ADD_UPDATE_WIDGET" });
            const data = await graphQl.executeMutation<{ addOrUpdateWidget: IWidgetModel }>(
                "addOrUpdateWidget(widgetInfo:$widgetInfo,file:$file){id title}",
                ["$widgetInfo:WidgetInputType!","$file:Upload"],
                { widgetInfo,file});
            dispatch({ type: "RESPOND_ADD_UPDATE_WIDGET", payload: data.addOrUpdateWidget, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_ADD_UPDATE_WIDGET", payload: null, error: e });
        }
    },
    requestGetWidgets: (page: number, pageSize: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_WIDGETS" });
            const params = { page: page, pageSize: pageSize };
            const data = await graphQl.executeQuery<{ widgets: IWidgetWrapper }>(
                "widgets(page: $page, pageSize: $pageSize){ items{  id title note order widgetPosition imageName linkTo alt active } page pageSize totalCount}",
                ["$page:Int", "$pageSize:Int"],
                {page: page, pageSize: pageSize});
            dispatch({ type: "RESPOND_GET_WIDGETS", payload: data.widgets, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_WIDGETS", payload: null, error: e });
        }

    },

    requestGetWidget: (id: number): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_WIDGET" });
            const data = await graphQl.executeQuery<{ widget: IWidgetModel }>("widget:widgetById(id:$id){ id title note order widgetPosition imageName linkTo alt active}", ["$id:Int!"], { id });
            dispatch({ type: "RESPOND_GET_WIDGET", payload: data.widget, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_WIDGET", payload: null, error: e });
        }

    },

    requestGetActiveStatus: (id: number, activeStatus: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_WIDGET_STATUS" });
            const data = await graphQl.executeQuery<{ widget: IWidgetModel }>("widgetStatus:widgetActiveStatus(id:$id, activeStatus: $activeStatus){ id active}", ["$id:Int!, $activeStatus: Boolean!"], { id, activeStatus });
            dispatch({ type: "RESPOND_GET_WIDGET_STATUS", payload: data.widget, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_WIDGET_STATUS", payload: null, error: e });
        }

    },
    requestGetActiveWidgets: (id: number, activeWidgets: boolean): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            dispatch({ type: "REQUEST_GET_ACTIVE_WIDGETS" });
            const data = await graphQl.executeQuery<{ widget: IWidgetModel }>("activeWidgets{id title note order imageName active linkTo alt widgetPosition}", [], { });
            dispatch({ type: "RESPOND_GET_ACTIVE_WIDGETS", payload: data.widget, error: null });
        } catch (e) {
            dispatch({ type: "RESPOND_GET_ACTIVE_WIDGETS", payload: null, error: e });
        }
    },
    requestGetWidgetsCount:
        (): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                try {
                    const params = { };
                    dispatch({ type: "REQUEST_GET_WIDGETS_COUNT" });
                    const data = await graphQl.executeQuery<{ totalWidgetsCount: IWidgetModel }>(
                        "totalWidgetsCount",
                        [],
                        {}
                    );
                    dispatch({
                        type: "RESPOND_GET_WIDGETS_COUNT",
                        payload: data.totalWidgetsCount,
                        error: null,
                    });
                } catch (e) {
                    dispatch({ type: "RESPOND_GET_WIDGETS_COUNT", payload: null, error: e });
                }
            },
    resetWidgetState: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: "RESET_STATE" });
    }
}

const unloadedState: IWidgetState = {
    widget: null,
    fetchError: null,
    isPosting: false,
    isLoading: false,
    isPosted: false,
    widgets: null,
    isLoaded: false,
    page: 1,
    pageSize: 10,
    totalCount: 0,
    totalWidgetsCount: null

}

export const reducer: Reducer<IWidgetState> = (state: IWidgetState | undefined, incomingAction: Action): IWidgetState => {
    if (state === undefined) {
        return { ...unloadedState };
    }
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_ADD_UPDATE_WIDGET":
            return {
                ...state,
                isPosting: true
            }
        case "RESPOND_ADD_UPDATE_WIDGET":
            return {
                ...state,
                widget: action.payload,
                fetchError: action.error,
                isPosting: false,
                isPosted: action.payload !== null
            }

        case "REQUEST_GET_WIDGETS":
        case "REQUEST_GET_WIDGET":
        case "REQUEST_GET_WIDGET_STATUS":
        case "REQUEST_GET_ACTIVE_WIDGETS":
        case "REQUEST_GET_WIDGETS_COUNT":
            return {
                ...state,
                widget: null,
                isLoading: true
            }
        case "RESPOND_GET_WIDGETS": {
            if (action.error) {
                return {
                    ...state
                };
            }
            let newState = {
                ...state,
                widgets: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            };
            if (action.payload) {
                newState = {
                    ...newState,
                    page: action.payload.page,
                    pageSize: action.payload.pageSize,
                    totalCount: action.payload.totalCount
                }

            }
            return newState;
        }

        case "RESPOND_GET_WIDGET": {
            return {
                ...state,
                widget: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "RESPOND_GET_WIDGETS_COUNT": {
            return {
                ...state,
                totalWidgetsCount: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }

        case "RESPOND_GET_WIDGET_STATUS": {
            return {
                ...state,
                widget: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "RESPOND_GET_ACTIVE_WIDGETS":{
            return {
                ...state,
                widget: action.payload,
                fetchError: action.error,
                isLoading: false,
                isLoaded: true
            }
        }
        case "RESET_STATE": {
            return {
                ...state,
                fetchError: null,
                isPosting: false,
                isLoading: false,
                isPosted: false,
                isLoaded: false,
                page: 1,
                pageSize: 10,
                totalCount: 0,
            }
        }
        default:
            return state;
    }

}