import { BASE_URL } from "../common/ApiUrls";
import { IAuthData } from "../models/AuthData";

export enum PostBodyType {
    JSON = ("application/json") as any,
    FormData = ("multipart/form-data") as any,
}

export interface IHttpService {

    getAsync<T>(url: string, params: string[] | null, authData?: IAuthData): Promise<T>;
    postAsync<T>(url: string, body: any, bodyType: PostBodyType, authData?: IAuthData): Promise<T>;
}

class HttpService implements IHttpService {

    async getAsync<T>(url: string, params: string[], authData?: IAuthData): Promise<T> {

        const myInit = {
            method: "GET",
            headers: this.getHeaders(authData)
        };

        const request = new Request(`${BASE_URL}${url}`, myInit);

        const response = await fetch(request);
        if (!response.ok) {
            throw new Error(await response.text());
        }

        return response.json();
    }

    async postAsync<T>(url: string, body: any, bodyType: PostBodyType = PostBodyType.JSON, authData?: IAuthData): Promise<T> {
        const myInit = {
            method: "POST",
            headers: this.getHeaders(authData),
            body: null as any
        };

        if (bodyType === PostBodyType.JSON) {
            myInit.headers.append("Content-Type", bodyType.toString());
            myInit.body = JSON.stringify(body);
        } else if (bodyType === PostBodyType.FormData) {
            myInit.body = body;
        }
        const request = new Request(`${BASE_URL}${url}`, myInit);

        try {
            const response = await fetch(request);
            if (!response.ok) {
                throw new Error(await response.text());
            }
            return response.json();
        } catch (e) {
            console.log(e);
            return e as any;
        }


    }

    


    private getHeaders(authData?: IAuthData): Headers {
        const header = new Headers();
        if (authData && authData != null && authData.token != null) {
            header.append("Authentication", `bearer ${authData.token}`);
        }

        return header;
    }
}

export default new HttpService() as IHttpService;