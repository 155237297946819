import { Action, Reducer } from "redux";
import { ICategoryModel } from "../models/CategoryModel";
import { INewsImage } from "../models/NewsImageModel";
import {INewsModel, INewsWrapper} from "../models/NewsModel";
import graphQl from "../services/GraphQL";
import http, { PostBodyType } from "../services/HttpService";
import { AppThunkAction } from "./index";

export interface INewsState {
  newsObj: INewsModel | null,
  fetchError: Error | null,
  isPosting: boolean,
  isLoading: boolean,
  isLoaded: boolean,
  isPosted: boolean,
  categories: Array<ICategoryModel> | null,
  news: INewsWrapper | null,
  mostViewNews: Array<INewsModel> | null,
  images: INewsImage | null,
  page: number,
  pageSize: number,
  totalCount: number,
  todayNewsCount: INewsModel | null,
  totalNewsCount: INewsModel | null,
  latestNews: Array<INewsModel> | null
}
//Request Get Actions
export interface RequestGetNews extends Action<string> {
  type: "REQUEST_GET_NEWS";
}
export interface RequestGetMostViewNews extends Action<string> {
  type: "REQUEST_GET_MOST_VIEW_NEWS";
}
export interface RequestGetNewsById extends Action<string> {
  type: "REQUEST_GET_NEWS_BY_ID";
}
export interface RequestGetTodayNewsCount extends Action<string> {
  type: "REQUEST_GET_TODAY_NEWS_COUNT";
}
export interface RequestGetTotalNewsCount extends Action<string> {
  type: "REQUEST_GET_TOTAL_NEWS_COUNT";
}
export interface RequestGetLatestNews extends Action<string> {
  type: "REQUEST_GET_LATEST_NEWS";
}
export interface RequestUploadImage extends Action<string> {
  type: "REQUEST_UPLOAD_IMAGE";
}
export interface RequestSearchNews extends Action<string> {
  type: "REQUEST_SEARCH_NEWS";
}
//Request Add Actions
export interface RequestAddOrUpdateNews extends Action<string> {
  type: "REQUEST_ADD_UPDATE_NEWS";
}

//Response Get Actions
export interface RespondGetNews extends Action<string> {
  type: "RESPOND_GET_NEWS";
  payload: INewsWrapper | null;
  error: Error | null;
}
export interface RespondGetMostViewNews extends Action<string> {
  type: "RESPOND_GET_MOST_VIEW_NEWS";
  payload: Array<INewsModel> | null;
  error: Error | null;
}
export interface RespondGetNewsById extends Action<string> {
  type: "RESPOND_GET_NEWS_BY_ID";
  payload: INewsModel | null;
  error: Error | null;
}
export interface RespondGetTodayNewsCount extends Action<string> {
  type: "RESPOND_GET_TODAY_NEWS_COUNT";
  payload: INewsModel | null;
  error: Error | null;
}
export interface RespondGetTotalNewsCount extends Action<string> {
  type: "RESPOND_GET_TOTAL_NEWS_COUNT";
  payload: INewsModel | null;
  error: Error | null;
}
export interface RespondGetLatestNews extends Action<string> {
  type: "RESPOND_GET_LATEST_NEWS";
  payload: Array<INewsModel> | null;
  error: Error | null;
}
export interface RespondSearchNews extends Action<string> {
  type: "RESPOND_SEARCH_NEWS";
  payload: INewsWrapper | null;
  error: Error | null;
}
//Respond Add Actions
export interface RespondAddOrUpdateNews extends Action<string> {
  type: "RESPOND_ADD_UPDATE_NEWS";
  payload: INewsModel | null;
  error: Error | null;
}
export interface ResetState extends Action<string> {
  type: "RESET_STATE";
}

export interface RespondUploadImages extends Action<string> {
  type: "RESPOND_UPLOAD_IMAGES";
  payload: INewsImage | null;
  error: Error | null;
}

type KnownAction =
    | RequestGetNews
    | RequestGetMostViewNews
    | RequestGetNewsById
    | RespondGetNewsById
    | RequestAddOrUpdateNews
    | RespondGetNews
    | RespondGetMostViewNews
    | RespondAddOrUpdateNews
    | ResetState
    | RequestUploadImage
    | RespondUploadImages
    | RequestGetTodayNewsCount
    | RespondGetTodayNewsCount
    | RequestSearchNews
    | RespondSearchNews
    | RequestGetTotalNewsCount
    | RespondGetTotalNewsCount
    | RequestGetLatestNews
    | RespondGetLatestNews;

export const actionCreators = {
  requestAddOrUpdateNews:
    (newsInfo: INewsModel, imageFile: any): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      const appState = getState();
      try {
        dispatch({ type: "REQUEST_ADD_UPDATE_NEWS" });
        //const authData = appState.authorization!.authData;

        if (typeof imageFile !== "undefined" && imageFile != null) {
          var formData = new FormData();
          formData.append("imageFile", imageFile as any);

          let imageUploader = (await http.postAsync<object>(
            "api/imageUploads",
            formData,
            PostBodyType.FormData
          )) as any;
          newsInfo.titleImage = imageUploader.imageName;
        }
        const data = await graphQl.executeMutation<{
          addOrUpdateNews: INewsModel;
        }>(
          "addOrUpdateNews(newsInfo:$newsInfo){ id, title shortDescription,description, categoryId,newsTags{tag{value,id,active}}}",
          ["$newsInfo:NewsInputType!"],
          { newsInfo },
          null
        );

        dispatch({
          type: "RESPOND_ADD_UPDATE_NEWS",
          payload: data.addOrUpdateNews,
          error: null,
        });
        //dispatch({ type: "RESET_STATE" });
      } catch (e) {
        dispatch({ type: "RESPOND_ADD_UPDATE_NEWS", payload: null, error: e });
      }
    },

  requestGetNews:
    (page: number, pageSize: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      //const appState = getState();
      try {
        dispatch({ type: "REQUEST_GET_NEWS" });
        //const authData = appState.authorization!.authData;
        const params = { page: page, pageSize: pageSize };
        const data = await graphQl.executeQuery<{
          newsList: INewsWrapper;
        }>(
          "newsList(newsFilter: $newsFilter){items{id,title,category{title},author,createDate}page,pageSize,totalCount}",
          ["$newsFilter: NewsFilter"],
          { newsFilter:{page: page, pageSize: pageSize} }
        );
        dispatch({
          type: "RESPOND_GET_NEWS",
          payload: data.newsList,
          error: null,
        });
        //dispatch({ type: "RESET_PRODUCT" });
      } catch (e) {
        console.log(e);
        dispatch({ type: "RESPOND_GET_NEWS", payload: null, error: e });
      }
    },
  requestGetMostViewNews:
      (page: number, pageSize: number): AppThunkAction<KnownAction> =>
          async (dispatch, getState) => {
            //const appState = getState();
            try {
              dispatch({ type: "REQUEST_GET_MOST_VIEW_NEWS" });
              //const authData = appState.authorization!.authData;
              const params = { page: page, pageSize: pageSize };
              const data = await graphQl.executeQuery<{
                mostViewNews: Array<INewsModel>;
              }>(
                  " mostViewNews(page:$page,pageSize:$pageSize){id,title,createDate,titleImage,newsViewCount,category{title,id,titleUrl,parentCategory{titleUrl}}}",
                  ["$page:Int!", "$pageSize:Int!"],
                  { page: page, pageSize: pageSize }
              );
              dispatch({
                type: "RESPOND_GET_MOST_VIEW_NEWS",
                payload: data.mostViewNews,
                error: null,
              });
              //dispatch({ type: "RESET_PRODUCT" });
            } catch (e) {
              console.log(e);
              dispatch({ type: "RESPOND_GET_MOST_VIEW_NEWS", payload: null, error: e });
            }
          },
  requestGetLatestNews:
      (page: number, pageSize: number): AppThunkAction<KnownAction> =>
          async (dispatch, getState) => {
            try {
              dispatch({ type: "REQUEST_GET_LATEST_NEWS" });
              const params = { page: page, pageSize: pageSize };
              const data = await graphQl.executeQuery<{
                latestNews: Array<INewsModel>;
              }>(
                  " latestNews(page:$page, pageSize:$pageSize){title category{title}}",
                  ["$page:Int", "$pageSize:Int"],
                  { page: page, pageSize: pageSize }
              );
              dispatch({
                type: "RESPOND_GET_LATEST_NEWS",
                payload: data.latestNews,
                error: null,
              });
            } catch (e) {
              dispatch({ type: "RESPOND_GET_LATEST_NEWS", payload: null, error: e });
            }
          },
  requestGetNewsById:
    (id: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      //const appState = getState();
      try {
        dispatch({ type: "REQUEST_GET_NEWS_BY_ID" });
        //const authData = appState.authorization!.authData;
        const params = { id: id };
        const data = await graphQl.executeQuery<{ singleNews: INewsModel }>(
          "singleNews(id:$id){ id, title,shortDescription,description,author,subTitle,categoryId,category{title, titleUrl, parentCategory{titleUrl}},titleImage,createDate,newsTags{id tag{value,id,active}}}",
          ["$id:Int!"],
          { id: id }
        );
        dispatch({
          type: "RESPOND_GET_NEWS_BY_ID",
          payload: data.singleNews,
          error: null,
        });
        //dispatch({ type: "RESET_PRODUCT" });
      } catch (e) {
        dispatch({ type: "RESPOND_GET_NEWS_BY_ID", payload: null, error: e });
      }
    },
  requestGetTodayNewsCount:
      (): AppThunkAction<KnownAction> =>
          async (dispatch, getState) => {
            try {
              const params = { };
              dispatch({ type: "REQUEST_GET_TODAY_NEWS_COUNT" });
              const data = await graphQl.executeQuery<{ todayNewsCount: INewsModel }>(
                  "todayNewsCount",
                  [],
                  {}
              );
              dispatch({
                type: "RESPOND_GET_TODAY_NEWS_COUNT",
                payload: data.todayNewsCount,
                error: null,
              });
            } catch (e) {
              dispatch({ type: "RESPOND_GET_TODAY_NEWS_COUNT", payload: null, error: e });
            }
          },

  requestGetTotalNewsCount:
      (): AppThunkAction<KnownAction> =>
          async (dispatch, getState) => {
            try {
              const params = { };
              dispatch({ type: "REQUEST_GET_TOTAL_NEWS_COUNT" });
              const data = await graphQl.executeQuery<{ totalNewsCount: INewsModel }>(
                  "totalNewsCount",
                  [],
                  {}
              );
              debugger
              dispatch({
                type: "RESPOND_GET_TOTAL_NEWS_COUNT",
                payload: data.totalNewsCount,
                error: null,
              });
            } catch (e) {
              dispatch({ type: "RESPOND_GET_TOTAL_NEWS_COUNT", payload: null, error: e });
            }
          },
  requestSearchNews:
      (page: number, pageSize: number, search:string): AppThunkAction<KnownAction> =>
          async (dispatch, getState) => {
            try {
              dispatch({ type: "REQUEST_SEARCH_NEWS" });
              const params = { page: page, pageSize: pageSize };
              const data = await graphQl.executeQuery<{
                searchNews: INewsWrapper;
              }>(
                  "newsSearch(page:$page,pageSize:$pageSize,search:$search){items{id,title,category{title}}page,pageSize,totalCount}",
                  ["$page:Int!", "$pageSize:Int!","$search:String"],
                  { page: page, pageSize: pageSize, search: search }
              );
              dispatch({
                type: "RESPOND_SEARCH_NEWS",
                payload: data.searchNews,
                error: null,
              });
            } catch (e) {
              console.log(e);
              dispatch({ type: "RESPOND_SEARCH_NEWS", payload: null, error: e });
            }
          },

  resetNewsState: (): AppThunkAction<KnownAction> => async (dispatch) => {
    dispatch({ type: "RESET_STATE" });
  },
  requestUploadImage:
    (file: any): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: "REQUEST_UPLOAD_IMAGE" });
        const response = (await graphQl.executeMutation<{
          fileUpload: INewsImage;
        }>(
          "fileUpload(file:$file){imageUrl,imageName}",
          ["$file:Upload"],
          { file },
          null
        )) as any;
        dispatch({
          type: "RESPOND_UPLOAD_IMAGES",
          payload: response.fileUpload,
          error: null,
        });
      } catch (e) {
        dispatch({ type: "RESPOND_UPLOAD_IMAGES", payload: null, error: e });
      }
    },
};

const unloadedState: INewsState = {
  newsObj: null,
  fetchError: null,
  isPosting: false,
  isLoading: false,
  isPosted: false,
  categories: null,
  news: null,
  mostViewNews: null,
  isLoaded: false,
  images: null,
  page: 1,
  pageSize: 20,
  totalCount: 0,
  todayNewsCount: null,
  totalNewsCount: null,
  latestNews:null

};

export const reducer: Reducer<INewsState> = (
  state: INewsState | undefined,
  incomingAction: Action
): INewsState => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_ADD_UPDATE_NEWS":
      return {
        ...state,
        isPosting: true,
      };
    case "RESPOND_ADD_UPDATE_NEWS":
      let payload = action;
      return {
        ...state,
        newsObj: action.payload,
        fetchError: action.error,
        isPosting: false,
        isPosted: action.payload !== null,
      };

    case "REQUEST_GET_NEWS":
    case "REQUEST_GET_NEWS_BY_ID":
    case "REQUEST_GET_TODAY_NEWS_COUNT":
    case "REQUEST_SEARCH_NEWS":
    case "REQUEST_GET_LATEST_NEWS":
    case "REQUEST_GET_MOST_VIEW_NEWS":
    case "REQUEST_GET_TOTAL_NEWS_COUNT":
      return {
        ...state,
        isLoading: true,
      };
    case "RESPOND_GET_NEWS": {
      if (action.error) {
        return {
          ...state
        };
      }
      var newState = {
        ...state,
        news: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
      if (action.payload) {
        newState = {
            ...newState,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          totalCount: action.payload.totalCount
        }

      }
      return newState;
    }

    case "RESPOND_SEARCH_NEWS": {
      if (action.error) {
        return {
          ...state
        };
      }
      let searchState = {
        ...state,
        news: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
      if (action.payload) {
        searchState = {
          ...searchState,
          page: action.payload.page,
          pageSize: action.payload.pageSize,
          totalCount: action.payload.totalCount
        }
      }
      return searchState;
    }

    case "RESPOND_GET_MOST_VIEW_NEWS": {
      return {
        ...state,
        mostViewNews: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
    }
    case "RESPOND_GET_NEWS_BY_ID": {
      return {
        ...state,
        newsObj: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
    }
    case "RESPOND_GET_TODAY_NEWS_COUNT": {
      return {
        ...state,
        todayNewsCount: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
    }
    case "RESPOND_GET_TOTAL_NEWS_COUNT": {
      return {
        ...state,
        totalNewsCount: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
    }
    case "RESPOND_GET_LATEST_NEWS": {
      return {
        ...state,
        latestNews: action.payload,
        fetchError: action.error,
        isLoading: false,
        isLoaded: true,
      };
    }

    case "REQUEST_UPLOAD_IMAGE":
      return {
        ...state,
        isLoading: true,
      };

    case "RESPOND_UPLOAD_IMAGES": {
      return {
        ...state,
        images: action.payload,
        isLoaded: true,
        fetchError: action.error,
      };
    }

    case "RESET_STATE": {
      return {
        ...state,
        fetchError: null,
        isPosting: false,
        isLoading: false,
        isPosted: false,
        news: null,
        mostViewNews:null,
        isLoaded: false,
        page: 1,
        pageSize: 20,
        totalCount: 0,
        latestNews:null
      };
    }
    default:
      return state;
  }
};
