import AuthorizedLayout from "../components/Layout/AuthorizedLayout";
import DefaultLayout from "../components/Layout/DefaultLayout";
import { IRoute, RouteBuilder } from "../models/RouteModels";

const routes: IRoute[] = [
  RouteBuilder.getBuilder(1)
    .setPath("/login")
    .setComponent(DefaultLayout)
    .build(),
  RouteBuilder.getBuilder(2)
    .setPath("/")
    .setComponent(AuthorizedLayout)
    .setIsPrivate(true)
    .build(),
];

export default routes;
