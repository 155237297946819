import * as React from "react";
import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io5";
import { IconContext } from "react-icons/lib";
import { Link, NavLink } from "react-router-dom";
import { Card, CardBody, Collapse } from "reactstrap";
import styled from "styled-components";
import { IRoute } from "../../models/RouteModels";
import "../sidebar/sidebar.css";
import logo from "../img/drishty-71-white.png";

const Nav: any = styled.div`
  background: #15171c;
  width: 80px;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: start;
`;

const NavIcon: any = styled(Link)`
  margin-left: 1.5rem;
  padding-bottom: 1rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  float: right;
  text-decoration: none;

`;
const NavIconClose: any = styled(Link)`
  margin-left: -1.5rem;
  padding-bottom: 3rem;
  padding-right: 0.2rem;
  font-size: 1.5rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  float: right;
  text-decoration: none;

`;
const SidebarNav: any = styled.nav`
  background: #15171c;
  width: 250px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }: any) => (sidebar ? "0" : "-100%")};
  transition: 850ms;
  z-index: 999;
  text-decoration: none;

`;
const Title: any = styled.span`
  padding: 1rem;
  color: #fff;
  font-family: cursive;
  text-decoration: none;

`;
const SidebarWarp: any = styled.div`
  width: 100%;
`;

const Svg: any = styled.span`
  width: 18px;
  margin-right: 40px;
  display: flex;
  align-items: center;
`;

const LogoutBtn = styled.button`
  display: flex;
  color: #e1e9fc;
  justify-content: flex-start;
  align-items: center;
  list-style: none;
  text-decoration: none;
  font-size: 18px;
  padding: 0px 20px 0px 20px;
  height: 60px;

  &:hover {
    background: #252831;
    border-left: 5px solid #632ce4;
    color: #e1e9fc;
    cursor: pointer;
  }

  width: 100%;
  outline: 0;
  background-color: transparent;
  border: 0;
  //display: flex;
  //align-items: center;
  //padding: 20px 55px 20px 30px;
  //text-decoration: none;
  transition: 0.15s ease-in-out;
  //margin-left: auto;
  margin-top: auto;
  margin-bottom: 25px;
  cursor: pointer;
`;

export interface SidebarProps {
  location?: Location;
  image?: any;
  hasImage?: boolean;
  color?: string;
  routes?: IRoute[];
}
export interface SidebarState {
  inWidth: number;
  opener: Object;
  sidebar: boolean;
}

class Sidebar extends React.PureComponent<SidebarProps, SidebarState> {
  state = {
    inWidth: window.innerWidth as number,
    opener: {} as any,
    sidebar: true,
  };
  activeRoute(routeName: string | string[]) {
    if (typeof routeName === "string") {
      return this.props.location &&
        this.props.location.pathname.indexOf(routeName) > -1
        ? "active"
        : "";
    } else {
      return routeName.some(
        ((r: string) =>
          this.props.location &&
          this.props.location.pathname.indexOf(r) > -1) as any
      )
        ? "active"
        : "";
    }
  }

  updateDimensions() {
    this.setState({ inWidth: window.innerWidth as number });
  }

  componentDidMount() {
    this.updateDimensions = this.updateDimensions.bind(this);
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  toggleOpen(path: string) {
    const st = { ...this.state.opener } as any;
    Object.keys(st)
      .filter((a) => a !== path)
      .map((a) => (st[a] = false));
    st[path] = !(st[path] || false);
    this.setState({ opener: st });
  }

  getRouteLink(prop: IRoute, key: number): any {
    if (prop.component == null && prop.children && prop.children.length > 0) {
      return (
        <li
          className={this.activeRoute(prop.children.map((a) => a.path))}
          key={key}
        >
          <NavLink
            to="#"
            className="nav-link"
            onClick={() => this.toggleOpen(prop.title as string)}
            activeClassName="active"
          >
            <i className={prop.icon as string}/>
            <p>{prop.title as string}</p>
          </NavLink>

          <Collapse isOpen={this.state.opener[prop.title as string] || false}>
              <CardBody style={{padding:'0 0 0 10px'}}>
                <ul className="nav">
                  {this.getRouteLinks(prop.children)}
                </ul>
              </CardBody>
          </Collapse>
        </li>
      );
    }
    return (
      <li className={this.activeRoute(prop.path)} key={key} >
        <NavLink to={prop.path} className="nav-link" activeClassName="active">
          <i className={prop.icon as string}/>
          <p>{prop.title as string}</p>
        </NavLink>
      </li>
    );
  }
  getRouteLinks(routes: IRoute[] | undefined) {
    return (
      routes &&
      routes
        .filter((a) => a.isLink && !a.redirect)
        .map((key, value) => this.getRouteLink(key, value))
    );
  }

  showSidebar() {
    this.setState((prevState) => ({
      sidebar: !prevState.sidebar,
    }));
  }

  render() {
    const sidebarBackground = {
      //  backgroundImage: `url(${this.props.image})`
      backgroundColor: "#000",
    };
    return (
      <div>
        <IconContext.Provider value={{ color: "fff" }}>
          <Nav>
            <NavIcon to="#">
              <FaIcons.FaBars onClick={() => this.showSidebar()} />
            </NavIcon>
          </Nav>
          <SidebarNav sidebar={this.state.sidebar}>
            <SidebarWarp>
              <NavIconClose to="#">
                <IoIcons.IoClose onClick={() => this.showSidebar()} />
              </NavIconClose>
              <Title>
                {/*<h4 className="text-center">Drishty 71</h4>*/}
                <a href="https://admin.drishty71.com/"><img className="logo" src={logo} alt="logo" /></a>
                <hr />
              </Title>
              {this.getRouteLinks(this.props.routes)}
            </SidebarWarp>
          </SidebarNav>
        </IconContext.Provider>
      </div>
    );
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.updateDimensions);
  }
}

export default Sidebar;

// const Sidebar = () => {
//   const [sidebar, setSidebar] = useState(true);
//   const showSidebar = () => setSidebar(!sidebar);
//   const { signout } = useContext(AuthContext);
//   return (
//     <div>
//       <IconContext.Provider value={{ color: "fff" }}>
//         <Nav>
//           <NavIcon to="#">
//             <FaIcons.FaBars onClick={showSidebar} />
//           </NavIcon>
//         </Nav>
//         <SidebarNav sidebar={sidebar}>
//           <SidebarWarp>
//             <NavIconClose to="#">
//               <IoIcons.IoClose onClick={showSidebar} />
//             </NavIconClose>
//             <Title>
//               <h4 className="text-center">Drishty 71</h4>
//               <hr />
//             </Title>
//             {SidebarData.map((item, index) => {
//               return <SubMenu key={index} {...item} />;
//             })}

//             <LogoutBtn
//               onClick={() => {
//                 signout();
//               }}
//             >
//               <Svg>
//                 <LogoutIcon />
//               </Svg>
//               Logout
//             </LogoutBtn>
//           </SidebarWarp>
//         </SidebarNav>
//       </IconContext.Provider>
//     </div>
//   );
// };
// export default Sidebar;
